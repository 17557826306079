exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-patronat-js": () => import("./../../../src/pages/patronat.js" /* webpackChunkName: "component---src-pages-patronat-js" */),
  "component---src-pages-prelegenci-js": () => import("./../../../src/pages/prelegenci.js" /* webpackChunkName: "component---src-pages-prelegenci-js" */),
  "component---src-pages-rejestracja-js": () => import("./../../../src/pages/rejestracja.js" /* webpackChunkName: "component---src-pages-rejestracja-js" */),
  "component---src-pages-tematyka-js": () => import("./../../../src/pages/tematyka.js" /* webpackChunkName: "component---src-pages-tematyka-js" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-02-12-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-02-12/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-02-12-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-02-16-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-02-16/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-02-16-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-02-20-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-02-20/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-02-20-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-02-28-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-02-28/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-02-28-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-02-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-03-02/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-02-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-06-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-03-06/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-06-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-13-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-03-13/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-13-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-20-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-03-20/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-20-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-21-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-03-21/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-21-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-22-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-03-22/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-03-22-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-04-06-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-04-06/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-04-06-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-04-10-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-04-10/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-04-10-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-04-14-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-04-14/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-04-14-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-04-19-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-04-19/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-04-19-post-1-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-04-22-post-1-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=C:/_in-progress/_jsProjects/event-site-gatsby-3/src/aktualnosci/2024-04-22/post1.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-aktualnosci-2024-04-22-post-1-mdx" */)
}

